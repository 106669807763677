import axiosClient from "utils/api/axios-client";

const orderApi = {
    getAll: (params) => {
        const url = "/api/v1/tuktuk/orders";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/v1/tuktuk/orders/${id}`;
        return axiosClient.get(url);
    },
    updateStatus: (request) => {
        const url = `/api/v1/tuktuk/orders/${request._id}/status`;
        return axiosClient.put(url, request);
    },
};

export default orderApi;
