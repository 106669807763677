import { Divider, Row, Select, Space, Table, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_STATUS } from "utils/constants";
import Actions from "./components/Actions";
import ChangeStatusModal from "./components/ChangeStatus";
import * as requestActions from "./slice";

function Request() {
    const dispatch = useDispatch();
    const { requests, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.tuktukRequest);

    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [selectingRequest, setSelectingRequest] = useState(null);
    const [searchStatus, setSearchStatus] = useState("");

    useEffect(() => {
        dispatch(requestActions.fetchRequests(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenChangeStatusModal(false);
            setSelectingRequest(null);
        }
    }, [isCompleted]);

    useEffect(() => {
        onSearch(searchStatus);
    }, [searchStatus]);

    const onOpenChangeStatus = (request) => {
        setSelectingRequest(request);
        setOpenChangeStatusModal(true);
    };

    const onChangeStatus = (request) => {
        dispatch(requestActions.updateRequestStatus(request));
    };

    const onCloseChangeStatusModal = () => {
        setOpenChangeStatusModal(false);
        setSelectingRequest(null);
        dispatch(requestActions.setIsProcessing(false));
    };

    const onChangeStatusSearch = (value) => {
        setSearchStatus(value);
    };

    const onSearch = (status) => {
        const params = { ...pagination };
        if (status) {
            params.status = status;
        }
        dispatch(requestActions.fetchRequests(params));
    };

    const onTableChange = async (pagination) => {
        dispatch(
            requestActions.fetchRequests({
                ...pagination,
                status: searchStatus,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Khách hàng",
            dataIndex: "user",
            render: (user) => (
                <>
                    <Typography.Text>{user.name}</Typography.Text>
                    <Typography.Paragraph>{user.phone}</Typography.Paragraph>
                </>
            ),
        },
        {
            title: "Điểm đón",
            dataIndex: "from",
        },
        {
            title: "Điểm đến",
            dataIndex: "to",
        },
        {
            title: "Số người",
            dataIndex: "quantity",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (status) => (
                <Tag color={REQUEST_STATUS[status]?.color || "default"}>
                    {REQUEST_STATUS[status]?.label || "Không xác định"}
                </Tag>
            ),
        },
        {
            title: "Thời gian",
            dataIndex: "createdAt",
            width: "10%",
            render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Thao tác",
            align: "center",
            render: (record) => (
                <Actions record={record} onEdit={onOpenChangeStatus} />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Yêu cầu gọi xe
                </Typography.Title>
                <Space size="middle">
                    <label className="font-semibold">Trạng thái:</label>
                    <Select
                        onChange={onChangeStatusSearch}
                        value={searchStatus}
                        options={[
                            {
                                value: "",
                                label: "Tất cả",
                            },
                            ...Object.values(REQUEST_STATUS),
                        ]}
                        className="w-60"
                    />
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={requests}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openChangeStatusModal && (
                <ChangeStatusModal
                    open={openChangeStatusModal}
                    onCancel={onCloseChangeStatusModal}
                    comboTransaction={selectingRequest}
                    onSubmit={onChangeStatus}
                    isProcessing={isProcessing}
                />
            )}
        </>
    );
}

export default Request;
