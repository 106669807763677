import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToCurrency } from "utils/common";
import { COMBO_TRANSACTION_STATUS } from "utils/constants";
import Actions from "./components/Actions";
import ChangeStatusModal from "./components/ChangeStatus";
import ComboTransactionDetailModal from "./components/DetailModal";
import * as comboTransactionActions from "./slice";

function ComboTransaction() {
    const dispatch = useDispatch();
    const {
        comboTransactions,
        pagination,
        isLoading,
        isProcessing,
        isCompleted,
    } = useSelector((state) => state.tuktukComboTransaction);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [selectingComboTransaction, setSelectingComboTransaction] =
        useState(null);
    const [searchStatus, setSearchStatus] = useState("");

    useEffect(() => {
        dispatch(comboTransactionActions.fetchComboTransactions(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setOpenChangeStatusModal(false);
            setSelectingComboTransaction(null);
        }
    }, [isCompleted]);

    useEffect(() => {
        onSearch(searchStatus);
    }, [searchStatus]);

    const onCreateItem = () => {
        setSelectingComboTransaction(null);
        setOpenDetailModal(true);
    };

    const onOpenChangeStatus = (comboTransaction) => {
        setSelectingComboTransaction(comboTransaction);
        setOpenChangeStatusModal(true);
    };

    const onSubmit = (comboTransaction) => {
        dispatch(comboTransactionActions.createTransaction(comboTransaction));
    };

    const onChangeStatus = (comboTransaction) => {
        dispatch(
            comboTransactionActions.updateComboTransactionStatus(
                comboTransaction
            )
        );
    };

    const onCancel = () => {
        setOpenDetailModal(false);
        setSelectingComboTransaction(null);
        dispatch(comboTransactionActions.setIsProcessing(false));
    };

    const onCloseChangeStatusModal = () => {
        setOpenChangeStatusModal(false);
        setSelectingComboTransaction(null);
        dispatch(comboTransactionActions.setIsProcessing(false));
    };

    const onChangeStatusSearch = (value) => {
        setSearchStatus(value);
    };

    const onSearch = (status) => {
        const params = { ...pagination };
        if (status) {
            params.status = status;
        }
        dispatch(comboTransactionActions.fetchComboTransactions(params));
    };

    const onTableChange = async (pagination) => {
        dispatch(
            comboTransactionActions.fetchComboTransactions({
                ...pagination,
                status: searchStatus,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Khách hàng",
            dataIndex: "user",
            render: (user) => (
                <>
                    <Typography.Text>
                        {user.name}{" "}
                        {user.tuktuk?.ticket > 0
                            ? `(${user.tuktuk.ticket} lượt)`
                            : ""}
                    </Typography.Text>
                    <Typography.Paragraph>{user.phone}</Typography.Paragraph>
                </>
            ),
        },
        {
            title: "Gói",
            dataIndex: ["combo", "name"],
        },
        {
            title: "Số tiền",
            dataIndex: "amount",
            render: (amount) => priceToCurrency(amount),
        },
        {
            title: "Số lượt",
            dataIndex: "ticket",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (status) => (
                <Tag color={COMBO_TRANSACTION_STATUS[status].color}>
                    {COMBO_TRANSACTION_STATUS[status].label}
                </Tag>
            ),
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            width: "15%",
        },
        {
            title: "Thời gian",
            dataIndex: "createdAt",
            width: "10%",
            render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Thao tác",
            align: "center",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenChangeStatus}
                    editDisabled={
                        record.status !==
                        COMBO_TRANSACTION_STATUS.processing.value
                    }
                />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Yêu cầu mua gói tiết kiệm
                </Typography.Title>
                <Space size="middle">
                    <label className="font-semibold">Trạng thái:</label>
                    <Select
                        onChange={onChangeStatusSearch}
                        value={searchStatus}
                        options={[
                            {
                                value: "",
                                label: "Tất cả",
                            },
                            ...Object.values(COMBO_TRANSACTION_STATUS),
                        ]}
                        className="w-60"
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={onCreateItem}
                    >
                        Tạo giao dịch
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={comboTransactions}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <ComboTransactionDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            )}
            {openChangeStatusModal && (
                <ChangeStatusModal
                    open={openChangeStatusModal}
                    onCancel={onCloseChangeStatusModal}
                    comboTransaction={selectingComboTransaction}
                    onSubmit={onChangeStatus}
                    isProcessing={isProcessing}
                />
            )}
        </>
    );
}

export default ComboTransaction;
