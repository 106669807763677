import {
    CarOutlined,
    DollarOutlined,
    ScheduleOutlined,
    SearchOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Input,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Tag,
    Typography,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToCurrency } from "utils/common";
import { TUKTUK_ORDER_PAYMENT_METHODS } from "utils/constants";
import { fetchDrivers } from "../driver/slice";
import * as orderActions from "./slice";

const { RangePicker } = DatePicker;

const RANGE_PRESETS = [
    {
        label: "Hôm nay",
        value: [dayjs(), dayjs()],
    },
    {
        label: "Hôm qua",
        value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    },
    {
        label: "Tuần này",
        value: [dayjs().startOf("week"), dayjs()],
    },
    {
        label: "Tuần trước",
        value: [
            dayjs().subtract(1, "week").startOf("week"),
            dayjs().subtract(1, "week").endOf("week"),
        ],
    },
    {
        label: "Tháng này",
        value: [dayjs().startOf("month"), dayjs()],
    },
    {
        label: "Tháng trước",
        value: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
        ],
    },
    {
        label: "Tất cả",
        value: [dayjs("01/07/2023", "DD/MM/YYYY"), dayjs()],
    },
];

function Order() {
    const dispatch = useDispatch();
    const { orders, stats, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.tuktukOrder);
    const { drivers } = useSelector((state) => state.tuktukDriver) || [];

    const driversOption = [
        { label: "Tất cả tài xế", value: "" },
        ...drivers
            .filter((driver) => driver.active)
            .map((driver) => ({
                label: driver.name,
                value: driver._id,
            })),
    ];

    const paymentMethodOptions = [
        { label: "Tất cả phương thức", value: "" },
        ...Object.keys(TUKTUK_ORDER_PAYMENT_METHODS).map((pm) => ({
            label: TUKTUK_ORDER_PAYMENT_METHODS[pm].label,
            value: pm,
        })),
    ];

    const [selectingOrder, setSelectingOrder] = useState(null);
    const [searchValue, setSearchValue] = useState({
        driver: "",
        paymentMethod: "",
        customer: "",
        range: [dayjs(), dayjs()],
        from: dayjs().format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD"),
    });
    const [searchState, setSearchState] = useState({
        driver: "",
        paymentMethod: "",
        customer: "",
        range: [dayjs(), dayjs()],
        from: dayjs().format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD"),
    });

    useEffect(() => {
        dispatch(orderActions.fetchOrders(pagination));
        dispatch(fetchDrivers({ pageSize: 100 }));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setSelectingOrder(null);
        }
    }, [isCompleted]);

    const onChangeSearchValue = (property, value, refresh) => {
        setSearchValue((prev) => ({ ...prev, [property]: value }));
        if (refresh) {
            setSearchState((prev) => ({ ...prev, [property]: value }));
            dispatch(
                orderActions.fetchOrders({
                    ...pagination,
                    ...searchValue,
                    [property]: value,
                })
            );
        }
    };

    const onChangeSearchRange = (dates) => {
        setSearchValue((prev) => ({
            ...prev,
            range: dates,
            from: dates[0].format("YYYY-MM-DD"),
            to: dates[1].format("YYYY-MM-DD"),
        }));
        setSearchState((prev) => ({
            ...prev,
            range: dates,
            from: dates[0].format("YYYY-MM-DD"),
            to: dates[1].format("YYYY-MM-DD"),
        }));
        dispatch(
            orderActions.fetchOrders({
                ...pagination,
                ...searchValue,
                from: dates[0].format("YYYY-MM-DD"),
                to: dates[1].format("YYYY-MM-DD"),
            })
        );
    };

    const onSearch = () => {
        setSearchState(searchValue);
        dispatch(
            orderActions.fetchOrders({
                ...pagination,
                ...searchValue,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            orderActions.fetchOrders({
                ...pagination,
                ...searchState,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (
                <Typography.Paragraph
                    copyable={{
                        text: record._id,
                        tooltips: ["Sao chép ID", "Đã sao chép!"],
                    }}
                >
                    {(pagination.current - 1) * pagination.pageSize + index + 1}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Khách hàng",
            render: (record) =>
                record.user ? (
                    <>
                        <Typography.Text>{record.user.name}</Typography.Text>
                        <Typography.Paragraph>
                            {record.user.phone}
                        </Typography.Paragraph>
                    </>
                ) : (
                    <>
                        <Typography.Text>GUEST</Typography.Text>
                        {record.phone && (
                            <Typography.Paragraph>
                                {record.phone}
                            </Typography.Paragraph>
                        )}
                    </>
                ),
        },
        {
            title: "Xe",
            dataIndex: "vehicle",
            width: "12%",
        },
        {
            title: "Tài xế",
            dataIndex: ["driver", "name"],
            width: "15%",
        },
        {
            title: "Số người",
            dataIndex: "quantity",
            width: "10%",
        },
        {
            title: "Số lượt trừ",
            dataIndex: "ticket",
            width: "10%",
        },
        {
            title: "Số tiền",
            dataIndex: "amount",
            render: (amount) => priceToCurrency(amount),
        },
        {
            title: "Thanh toán",
            width: "15%",
            render: (record) =>
                record.amount > 0 ? (
                    <Tag
                        color={
                            TUKTUK_ORDER_PAYMENT_METHODS[record.paymentMethod]
                                .color
                        }
                    >
                        {
                            TUKTUK_ORDER_PAYMENT_METHODS[record.paymentMethod]
                                .label
                        }
                    </Tag>
                ) : (
                    <Tag>Trừ lượt</Tag>
                ),
        },
        {
            title: "Thời gian",
            dataIndex: "createdAt",
            width: "10%",
            render: (createdAt) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
        },
        // {
        //     title: "Thao tác",
        //     align: "center",
        //     render: (record) => (
        //         <Actions
        //             record={record}
        //             onEdit={onOpenChangeStatus}
        //             editDisabled={
        //                 record.status !== TUKTUK_ORDER_PAYMENT_METHODS.processing.value
        //             }
        //         />
        //     ),
        // },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    [{pagination?.total || "0"}] Lịch sử chuyến đi
                </Typography.Title>
                <Space size="middle">
                    <RangePicker
                        presets={RANGE_PRESETS}
                        value={searchValue.range}
                        onChange={onChangeSearchRange}
                        style={{ width: "250px" }}
                    />
                    <Select
                        options={driversOption}
                        onChange={(value) =>
                            onChangeSearchValue("driver", value, true)
                        }
                        value={searchValue.driver}
                        allowClear
                        placeholder="Chọn tài xế"
                        style={{ width: "160px" }}
                    />
                    <Select
                        options={paymentMethodOptions}
                        onChange={(value) =>
                            onChangeSearchValue("paymentMethod", value, true)
                        }
                        value={searchValue.paymentMethod}
                        allowClear
                        placeholder="Phương thức thanh toán"
                        style={{ width: "180px" }}
                    />
                    <Input
                        placeholder="SĐT khách hàng"
                        onChange={(e) =>
                            onChangeSearchValue("customer", e.target.value)
                        }
                        allowClear
                        value={searchValue.customer}
                    />

                    <Button
                        onClick={onSearch}
                        icon={<SearchOutlined />}
                        type="primary"
                    >
                        Tìm kiếm
                    </Button>
                </Space>
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Tổng chuyến đi"
                            value={stats.trips || 0}
                            prefix={<CarOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Tổng số người"
                            value={stats.quantity || 0}
                            prefix={<UserOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Tổng tiền thu khách"
                            value={priceToCurrency(stats.amount || 0)}
                            prefix={<DollarOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Tổng lượt bị trừ"
                            value={stats.tickets || 0}
                            prefix={<ScheduleOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
            </Row>
            <Divider />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={orders}
                rowKey={(record) => record._id}
                pagination={pagination}
                onChange={onTableChange}
            />
        </>
    );
}

export default Order;
