import { createSlice } from "@reduxjs/toolkit";
import orderApi from "./api";

const initialState = {
    orders: [],
    stats: {},
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const orderSlice = createSlice({
    name: "tuktuk-order",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addOrder: (state, action) => {
            state.isCompleted = true;
            state.orders.unshift(action.payload);
        },
        editOrder: (state, action) => {
            state.isCompleted = true;
            state.orders = state.orders.map((order) =>
                order._id === action.payload._id ? action.payload : order
            );
        },
        setStats: (state, action) => {
            state.stats = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setOrders,
    setPagination,
    addOrder,
    editOrder,
    removeOrder,
    setStats,
} = orderSlice.actions;

export const fetchOrders = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await orderApi.getAll(params);
            dispatch(setOrders(data.data.orders));
            dispatch(setStats(data.data.stats));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};
export default orderSlice.reducer;
